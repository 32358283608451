// import React from "react"
// import Header from "../components/header"
// import Layout from "../components/layout"

// const about = () => {
//   return <Layout>
//     <Header headerText="关于我" />
//     <div>欢迎参观咱的博客!</div>
//     <div>&nbsp;</div>
//     <div>这年头已经没人会写博客了吧&gt;w&lt;</div>
//     <div>咱只是写给自己看的。只是想起来，有了心情，或者刚好有东西可发才更新一下。博客应该是长期的，所以最近三年应该都不会关。</div>
//     <p>博客是高一开始建的，当时是WordPress，可是维护成本略高，就转静态了。东西是写给咱自己看的，你也可以看看咱自己，就是别在我面前看，会有一种被看到内裤的感觉&gt;ω&lt;</p>
//   </Layout>
// }
// export default about;

import React from 'react';
import { Link } from 'gatsby-theme-material-ui';
import { Container, Box, Typography } from '@mui/material';

import ProTip from '../components/pro-tip';

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Built with love by the `}
      <Link color="inherit" href="https://material-ui.com/">
        Material-UI
      </Link>
      {` team.`}
    </Typography>
  );
}

export default function about() {
  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          gatsby-theme-material-ui example
        </Typography>
        <Link to="/">Go to the main page</Link>
        <ProTip />
        <MadeWithLove />
      </Box>
    </Container>
  );
}
